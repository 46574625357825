import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 811.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,811.000000) scale(0.100000,-0.100000)">
					<path d="M4232 6016 c-271 -542 -492 -987 -492 -991 0 -3 55 -4 123 -3 l122 3
0 40 0 40 -52 3 c-29 2 -53 5 -53 8 0 9 845 1694 850 1694 3 0 74 -140 159
-312 l154 -311 -286 -574 c-158 -315 -287 -578 -287 -583 0 -6 47 -10 120 -10
l120 0 0 45 0 45 -51 0 c-43 0 -50 2 -45 18 3 9 43 91 89 182 l84 165 306 3
305 2 90 -182 90 -183 -54 -3 -54 -3 0 -44 0 -45 125 0 c69 0 125 2 125 5 0 3
-130 266 -290 584 l-289 579 140 282 c77 155 149 295 159 312 l19 31 425 -852
426 -851 -55 0 -55 0 0 -45 0 -45 121 0 c103 0 120 2 117 15 -5 27 -973 1964
-980 1964 -5 1 -88 -160 -187 -356 l-179 -358 -178 358 c-98 196 -182 357
-185 357 -3 0 -227 -443 -497 -984z m997 -198 c72 -145 131 -266 131 -270 0
-5 -122 -8 -271 -8 -149 0 -269 4 -267 8 18 45 265 531 269 532 4 0 66 -118
138 -262z"/>
					<path d="M8561 5728 c1 -63 4 -119 7 -125 4 -5 50 -13 102 -17 451 -33 863
-289 1095 -678 108 -182 178 -402 193 -611 l7 -102 125 0 125 0 1 40 c1 74
-24 252 -50 356 -173 693 -789 1212 -1478 1246 l-127 6 0 -115z"/>
					<path d="M8566 5322 c-2 -4 -3 -61 -3 -127 l2 -120 40 -2 c146 -9 294 -54 413
-127 247 -150 409 -417 425 -701 l2 -50 128 -3 c147 -3 134 -18 117 133 -18
156 -69 302 -158 453 -44 75 -84 124 -174 215 -64 64 -121 117 -126 117 -6 0
-17 6 -25 14 -19 20 -171 100 -236 126 -121 47 -390 95 -405 72z"/>
					<path d="M3978 4874 c-11 -11 1 -24 23 -24 18 0 19 -8 19 -121 0 -112 -1 -121
-20 -126 -41 -11 -18 -23 45 -23 37 0 65 4 65 10 0 6 -11 10 -25 10 -23 0 -25
3 -25 50 0 72 26 68 70 -10 32 -57 36 -60 72 -60 21 0 38 5 38 10 0 6 -7 10
-14 10 -16 0 -66 60 -66 78 0 7 -4 12 -10 12 -17 0 -11 18 15 44 31 31 33 83
6 117 -17 21 -29 24 -103 27 -45 2 -86 1 -90 -4z m160 -51 c27 -37 5 -80 -43
-85 -30 -3 -30 -3 -33 56 l-3 59 33 -7 c18 -3 39 -14 46 -23z"/>
					<path d="M4319 4863 c-35 -21 -51 -49 -60 -103 -15 -92 27 -173 97 -186 50 -9
114 11 114 36 0 24 1 24 -40 4 -68 -34 -124 18 -125 116 0 75 25 114 78 118
31 3 36 0 39 -20 2 -17 10 -23 28 -23 20 0 25 5 25 25 0 46 -100 68 -156 33z"/>
					<path d="M4820 4873 c-8 -4 -30 -17 -48 -31 -37 -28 -30 -48 11 -33 l27 11 0
-110 0 -110 -30 0 c-16 0 -30 -4 -30 -10 0 -6 33 -10 80 -10 47 0 80 4 80 10
0 6 -11 10 -25 10 l-25 0 0 140 c0 144 -1 148 -40 133z"/>
					<path d="M4990 4858 c-24 -26 -25 -51 -3 -88 15 -27 15 -29 -5 -55 -33 -41
-30 -99 5 -122 41 -27 115 -24 147 6 35 33 35 83 2 112 l-24 21 19 32 c10 17
19 41 19 53 0 56 -117 87 -160 41z m112 -33 c9 -19 8 -29 -6 -50 -16 -24 -18
-25 -46 -10 -55 28 -47 85 11 85 22 0 32 -7 41 -25z m2 -146 c23 -34 -5 -79
-50 -79 -34 0 -58 34 -50 71 10 51 23 61 56 44 16 -8 36 -25 44 -36z"/>
					<path d="M5273 4852 c-56 -35 -53 -55 5 -37 22 6 22 6 22 -104 l0 -111 -30 0
c-16 0 -30 -4 -30 -10 0 -6 33 -10 80 -10 44 0 80 4 80 9 0 5 -12 11 -27 13
l-28 3 -3 138 c-2 100 -6 137 -15 137 -7 0 -31 -13 -54 -28z"/>
					<path d="M5446 4858 c-9 -36 -7 -48 9 -48 8 0 15 7 15 15 0 12 14 15 65 15 36
0 65 -2 65 -5 0 -2 -13 -25 -30 -51 -31 -49 -54 -112 -66 -177 -6 -36 -5 -39
16 -35 20 3 25 13 36 68 8 36 31 101 54 145 22 44 40 83 40 88 0 4 -44 7 -99
7 -94 0 -99 -1 -105 -22z"/>
					<path d="M5763 4863 c-3 -10 -24 -56 -45 -102 -21 -46 -38 -88 -38 -92 0 -5
27 -9 60 -9 l60 0 0 -30 c0 -27 -3 -30 -30 -30 -16 0 -30 -4 -30 -10 0 -6 30
-10 70 -10 67 0 93 12 50 23 -29 8 -26 45 5 57 14 5 25 12 25 15 0 3 -11 10
-25 15 -21 8 -25 16 -25 50 0 34 -3 41 -17 38 -13 -2 -19 -15 -21 -46 -3 -41
-4 -42 -38 -42 -19 0 -34 4 -34 8 0 4 18 43 40 85 23 43 38 82 35 87 -10 16
-35 11 -42 -7z"/>
					<path d="M5962 4864 c-27 -18 -30 -74 -6 -99 15 -14 14 -19 -10 -53 -31 -46
-33 -71 -6 -105 37 -47 120 -48 166 -2 31 31 31 69 -1 101 -26 25 -32 44 -16
44 5 0 15 16 21 35 11 35 2 70 -24 87 -20 14 -101 8 -124 -8z m107 -32 c9 -17
-10 -82 -24 -82 -4 0 -20 9 -36 21 -30 21 -40 63 -16 72 28 12 67 6 76 -11z
m5 -166 c8 -20 6 -30 -9 -46 -40 -44 -103 -8 -91 51 10 54 24 62 60 40 17 -11
35 -31 40 -45z"/>
					<path d="M6202 4860 c-23 -22 -30 -64 -12 -75 6 -3 20 -3 31 0 17 6 20 13 15
36 -5 26 -3 29 22 29 57 0 70 -52 25 -96 -19 -18 -24 -28 -15 -31 45 -17 59
-93 21 -113 -33 -18 -54 -8 -54 25 0 25 -4 31 -27 33 -25 3 -28 0 -28 -26 0
-41 32 -67 85 -67 85 0 128 86 70 139 -25 23 -25 23 -5 41 29 26 27 89 -2 109
-33 23 -99 21 -126 -4z"/>
					<path d="M4530 4744 c-11 -12 -10 -18 4 -32 16 -16 18 -16 31 2 11 14 12 23 4
33 -15 17 -24 16 -39 -3z"/>
					<path d="M4537 4628 c-22 -16 -13 -48 13 -48 23 0 32 24 17 44 -10 14 -15 15
-30 4z"/>
					<path d="M5822 4271 c-9 -5 -30 -123 -62 -357 -76 -540 -90 -634 -100 -700 -6
-34 -10 -75 -10 -92 0 -29 2 -30 68 -45 126 -30 217 -41 337 -40 174 1 250 34
318 138 58 89 102 319 96 496 -5 125 -24 169 -90 214 -41 28 -50 30 -134 30
-81 -1 -96 -4 -151 -32 -65 -34 -190 -124 -211 -152 -20 -28 -24 -5 -9 55 21
82 76 481 68 489 -9 8 -106 5 -120 -4z m473 -501 c15 -14 27 -39 31 -67 9 -60
-11 -261 -36 -358 -33 -130 -66 -163 -180 -186 -67 -13 -294 -9 -309 6 -6 6
-1 66 14 165 12 85 26 180 29 211 4 31 13 64 19 72 36 45 206 155 277 178 47
15 128 4 155 -21z"/>
					<path d="M1690 4250 c-73 -5 -100 -13 -100 -30 0 -4 -25 -61 -56 -126 -31 -66
-71 -150 -89 -189 -18 -38 -61 -128 -95 -200 -34 -71 -74 -157 -90 -190 -28
-62 -121 -256 -183 -382 -19 -40 -31 -73 -26 -75 5 -2 40 -2 78 0 l69 4 22 51
c13 29 46 102 75 162 29 61 55 120 59 133 l7 22 254 0 c207 0 254 -3 258 -14
3 -8 18 -91 33 -185 l27 -171 73 0 c41 0 74 3 74 6 0 3 -13 72 -30 152 -16 81
-43 217 -59 302 -17 85 -53 274 -81 419 -29 145 -56 274 -60 286 -10 27 -47
33 -160 25z m54 -109 c3 -5 10 -37 16 -72 5 -35 21 -129 35 -209 39 -214 45
-253 45 -277 l0 -23 -205 0 c-113 0 -205 2 -205 4 0 9 39 96 129 290 23 50 61
133 84 185 23 53 46 99 52 103 14 11 43 10 49 -1z"/>
					<path d="M8051 4242 c-5 -10 -13 -46 -17 -81 -8 -75 -3 -81 73 -81 64 0 74 14
77 109 l1 66 -62 3 c-52 2 -63 0 -72 -16z"/>
					<path d="M6840 4235 c-13 -16 -30 -118 -90 -555 -23 -162 -50 -356 -60 -430
-11 -74 -20 -148 -22 -164 l-3 -29 70 2 c38 1 71 3 72 4 1 2 19 129 83 587 10
74 29 210 41 303 17 124 26 167 36 167 7 0 15 -6 17 -12 15 -53 59 -187 67
-203 5 -11 33 -90 63 -175 164 -469 221 -627 234 -648 14 -22 20 -23 121 -20
l106 3 11 40 c6 22 37 227 69 455 31 228 66 471 76 540 10 69 19 131 19 138 0
8 -21 12 -73 12 -72 0 -73 0 -75 -27 -4 -47 -13 -116 -87 -653 -8 -58 -22
-161 -30 -230 -9 -69 -18 -131 -20 -137 -13 -38 -35 -1 -87 150 -54 159 -73
212 -178 507 -29 80 -59 168 -67 195 -9 28 -19 55 -23 60 -4 6 -11 24 -14 40
-4 17 -15 45 -26 63 l-20 32 -99 0 c-74 0 -102 -4 -111 -15z"/>
					<path d="M2755 3910 c-72 -18 -143 -60 -232 -138 -35 -29 -64 -50 -64 -45 -1
4 -1 45 1 91 l2 82 -50 0 c-59 0 -52 16 -81 -190 -58 -402 -91 -633 -91 -641
0 -5 32 -9 70 -9 39 0 70 3 70 6 0 14 71 530 75 540 6 19 177 152 222 174 59
28 151 28 181 0 35 -33 32 -96 -27 -499 -19 -126 -28 -217 -22 -220 5 -3 36
-5 69 -3 l60 4 5 46 c7 61 34 259 53 392 8 57 20 110 26 118 28 33 177 146
216 163 64 28 142 26 176 -6 36 -33 35 -60 -15 -413 -23 -161 -40 -295 -37
-297 9 -9 118 -9 127 0 5 6 16 62 25 125 9 63 30 213 47 334 36 261 33 294
-33 351 -104 89 -264 52 -445 -102 l-50 -42 -12 54 c-24 108 -134 159 -266
125z"/>
					<path d="M4004 3910 c-54 -11 -114 -37 -138 -58 -33 -30 -56 -93 -56 -160 0
-104 34 -149 160 -209 115 -54 200 -93 205 -93 19 0 117 -55 129 -72 31 -44 9
-114 -43 -138 -42 -20 -216 -31 -367 -24 l-141 7 -6 -49 c-3 -27 -4 -50 -4
-51 10 -10 192 -23 317 -23 249 0 320 23 367 118 32 66 37 159 10 209 -25 46
-58 66 -265 159 -112 50 -196 94 -209 109 -26 30 -29 62 -9 109 12 29 21 35
66 45 28 6 145 11 261 11 229 0 220 -3 223 62 1 29 -3 33 -34 40 -51 12 -418
19 -466 8z"/>
					<path d="M8610 3911 c-84 -18 -150 -68 -197 -148 -38 -65 -82 -259 -90 -394
-7 -131 3 -192 43 -251 86 -132 338 -102 517 62 70 65 76 65 52 3 -8 -21 -23
-75 -34 -120 -32 -131 -65 -177 -154 -218 -69 -32 -162 -45 -328 -45 l-136 0
-6 -42 c-3 -24 -5 -44 -3 -45 11 -12 132 -23 245 -23 235 0 372 56 453 186 52
82 53 89 160 841 12 84 19 156 15 160 -26 25 -455 53 -537 34z m385 -163 c-3
-29 -15 -105 -25 -168 -10 -63 -22 -141 -25 -172 -6 -47 -13 -62 -40 -87 -123
-111 -220 -161 -318 -161 -58 0 -71 3 -92 24 -34 34 -40 84 -26 211 24 207 53
307 104 354 41 38 107 50 275 50 l154 1 -7 -52z"/>
					<path d="M4737 3713 c-70 -488 -71 -502 -58 -551 15 -58 34 -79 91 -105 65
-30 157 -27 238 6 68 28 169 96 216 146 16 16 33 28 38 25 6 -4 7 -41 3 -90
l-7 -84 54 0 55 0 56 403 c32 221 57 410 57 420 0 16 -8 18 -67 15 l-68 -3
-38 -270 -38 -270 -32 -28 c-69 -60 -146 -117 -188 -138 -53 -28 -159 -38
-195 -19 -27 15 -45 66 -38 113 9 71 37 261 60 420 13 92 24 174 24 182 0 12
-14 15 -68 15 l-68 0 -27 -187z"/>
					<path d="M7991 3823 c-6 -43 -29 -208 -51 -368 -23 -159 -44 -314 -49 -343
l-8 -53 70 3 70 3 33 230 c17 127 42 302 54 390 12 88 24 172 27 188 l6 27
-71 0 -70 0 -11 -77z"/>
					<path d="M4317 2867 c-3 -8 -4 -33 -1 -56 2 -22 2 -41 0 -41 -2 0 -17 5 -35
11 -44 15 -78 4 -101 -34 -58 -94 21 -208 111 -162 17 9 29 11 29 5 0 -5 7
-10 15 -10 13 0 15 21 13 147 -2 97 -7 148 -14 150 -6 2 -14 -2 -17 -10z m-29
-116 c17 -10 31 -71 24 -106 -6 -31 -40 -50 -76 -41 -62 15 -52 156 11 156 15
0 34 -4 41 -9z"/>
					<path d="M4460 2867 c-16 -8 -27 -26 -33 -50 -5 -21 -15 -37 -23 -37 -8 0 -14
-4 -14 -10 0 -5 7 -10 15 -10 11 0 15 -19 17 -87 2 -58 7 -88 16 -91 9 -3 12
16 12 71 0 92 5 107 37 107 15 0 23 5 20 13 -2 6 -11 12 -20 12 -25 0 -36 17
-26 43 6 16 16 22 40 22 20 0 29 4 27 13 -6 17 -39 19 -68 4z"/>
					<path d="M4910 2730 c0 -127 2 -150 15 -150 12 0 15 15 15 73 1 86 13 107 63
107 43 0 47 -9 47 -106 0 -59 3 -74 15 -74 21 0 22 157 1 185 -15 20 -76 26
-108 11 -16 -7 -18 -2 -18 48 0 43 -3 56 -15 56 -13 0 -15 -23 -15 -150z"/>
					<path d="M5919 2874 c-18 -5 -39 -45 -39 -74 0 -11 -7 -20 -15 -20 -8 0 -15
-4 -15 -10 0 -5 7 -10 15 -10 12 0 15 -17 15 -90 0 -73 3 -90 15 -90 12 0 15
17 15 89 l0 90 28 3 c15 2 27 8 27 13 0 6 -12 11 -27 13 -30 4 -31 5 -22 40 5
16 14 22 36 22 19 0 28 4 26 13 -5 13 -32 18 -59 11z"/>
					<path d="M6250 2819 l0 -60 -22 15 c-27 19 -48 20 -81 5 -31 -14 -47 -48 -47
-99 0 -51 16 -85 47 -99 31 -14 42 -14 75 3 16 9 30 11 33 5 19 -30 25 5 25
141 0 127 -2 150 -15 150 -12 0 -15 -13 -15 -61z m-16 -81 c21 -29 21 -102 0
-122 -8 -9 -27 -16 -41 -16 -40 0 -63 27 -63 74 0 54 23 86 60 86 18 0 34 -8
44 -22z"/>
					<path d="M7440 2730 c0 -127 2 -150 15 -150 13 0 15 23 15 150 0 127 -2 150
-15 150 -13 0 -15 -23 -15 -150z"/>
					<path d="M7540 2730 c0 -127 2 -150 15 -150 13 0 15 23 15 150 0 127 -2 150
-15 150 -13 0 -15 -23 -15 -150z"/>
					<path d="M3331 2842 c-54 -27 -74 -62 -74 -127 0 -67 20 -101 77 -129 32 -16
48 -17 95 -10 l56 9 0 70 0 70 -44 0 c-53 0 -68 -22 -19 -27 31 -3 33 -6 36
-42 4 -45 -7 -56 -56 -56 -77 0 -119 52 -109 136 7 70 32 89 114 89 71 1 96
18 42 29 -51 10 -82 7 -118 -12z"/>
					<path d="M4782 2817 c-6 -15 -19 -30 -27 -33 -19 -8 -19 -24 0 -24 12 0 15
-15 15 -73 0 -43 5 -80 13 -90 15 -21 68 -27 74 -9 3 7 -3 12 -14 12 -33 0
-43 21 -43 92 l0 68 30 0 c39 0 39 16 1 25 -23 5 -31 13 -33 33 l-4 27 -12
-28z"/>
					<path d="M6582 2817 c-6 -15 -19 -30 -27 -33 -19 -8 -19 -24 0 -24 12 0 15
-15 15 -73 0 -43 5 -80 13 -90 15 -21 68 -27 74 -9 3 7 -4 12 -15 12 -33 0
-43 24 -40 92 3 60 4 63 31 66 16 2 27 9 27 18 0 8 -6 14 -12 13 -35 -4 -47 3
-50 29 l-4 27 -12 -28z"/>
					<path d="M3557 2784 c-4 -4 -7 -52 -7 -106 0 -80 3 -98 15 -98 12 0 15 13 15
61 0 84 20 119 68 119 15 0 22 5 20 13 -7 19 -38 20 -59 1 -18 -16 -20 -16
-32 0 -7 9 -16 14 -20 10z"/>
					<path d="M3747 2781 c-35 -13 -20 -26 29 -26 46 0 49 -2 52 -27 3 -25 0 -28
-28 -28 -17 0 -46 -7 -65 -14 -30 -13 -35 -20 -35 -49 0 -56 53 -81 109 -53
17 9 31 12 31 6 0 -5 5 -10 10 -10 6 0 10 36 10 89 0 82 -2 91 -22 105 -24 17
-60 20 -91 7z m83 -125 c0 -35 -27 -58 -64 -54 -26 2 -32 8 -34 31 -4 30 19
45 71 46 22 1 27 -3 27 -23z"/>
					<path d="M3937 2784 c-4 -4 -7 -52 -7 -106 0 -80 3 -98 15 -98 12 0 15 14 15
63 0 82 15 111 60 115 48 5 60 -16 60 -105 0 -60 3 -73 16 -73 14 0 16 12 13
86 -4 76 -7 88 -28 105 -27 22 -69 25 -96 5 -17 -12 -21 -12 -30 1 -6 8 -14
11 -18 7z"/>
					<path d="M4583 2783 c-13 -2 -23 -11 -23 -19 0 -11 9 -13 40 -8 44 7 70 -6 70
-36 0 -14 -11 -19 -52 -22 -38 -4 -57 -12 -70 -28 -24 -30 -23 -46 7 -76 21
-21 31 -24 58 -19 18 4 38 12 46 19 11 8 15 8 18 -1 3 -7 9 -13 14 -13 5 0 9
38 9 85 0 115 -21 137 -117 118z m87 -126 c0 -33 -32 -59 -67 -55 -22 2 -29 9
-31 31 -4 30 19 45 71 46 22 1 27 -3 27 -22z"/>
					<path d="M5170 2762 c-25 -26 -30 -38 -30 -79 0 -27 7 -58 15 -70 21 -30 75
-46 117 -35 45 11 33 28 -19 24 -47 -4 -68 10 -79 52 l-6 26 71 0 71 0 0 29
c0 40 -28 71 -72 78 -32 5 -41 1 -68 -25z m98 -14 c7 -7 12 -20 12 -30 0 -15
-8 -18 -49 -18 -57 0 -71 13 -45 41 19 21 64 25 82 7z"/>
					<path d="M5377 2784 c-4 -4 -7 -52 -7 -106 0 -80 3 -98 15 -98 12 0 15 14 15
64 0 73 24 116 66 116 15 0 24 6 24 15 0 20 -30 19 -58 -1 -21 -15 -24 -15
-35 1 -7 9 -16 13 -20 9z"/>
					<path d="M5679 2779 c-58 -22 -74 -129 -26 -177 33 -33 94 -37 126 -8 42 38
44 127 4 167 -23 23 -70 31 -104 18z m83 -36 c24 -21 25 -99 1 -125 -22 -24
-65 -23 -89 3 -21 23 -24 82 -5 117 14 27 66 30 93 5z"/>
					<path d="M6383 2780 c-40 -16 -26 -30 23 -23 48 6 64 -3 64 -39 0 -13 -8 -18
-30 -18 -17 0 -46 -7 -65 -14 -31 -13 -35 -19 -35 -50 0 -27 6 -40 26 -53 25
-16 30 -16 70 -1 24 8 44 12 44 7 0 -5 5 -9 10 -9 6 0 10 36 10 89 0 82 -2 91
-22 105 -25 18 -61 20 -95 6z m87 -123 c0 -33 -32 -59 -67 -55 -24 3 -28 8
-28 33 0 23 6 31 25 36 51 14 70 11 70 -14z"/>
					<path d="M6743 2783 c-13 -2 -23 -11 -23 -19 0 -11 9 -13 40 -8 44 7 70 -6 70
-37 0 -15 -7 -19 -34 -19 -80 0 -124 -46 -92 -94 22 -34 57 -42 98 -22 20 11
34 13 36 6 2 -5 8 -10 13 -10 5 0 9 38 9 85 0 115 -21 137 -117 118z m87 -126
c0 -33 -32 -59 -67 -55 -22 2 -29 9 -31 31 -4 30 19 45 71 46 22 1 27 -3 27
-22z"/>
					<path d="M7042 2774 c-12 -8 -22 -25 -22 -38 0 -26 19 -45 73 -69 30 -14 38
-22 35 -40 -3 -20 -9 -22 -55 -22 -34 1 -53 -3 -53 -11 0 -15 52 -25 90 -18
38 8 52 26 48 61 -2 22 -13 33 -53 54 -34 17 -50 32 -50 45 0 16 7 19 47 19
56 0 71 11 34 25 -38 14 -68 13 -94 -6z"/>
					<path d="M7264 2780 c-12 -5 -29 -19 -38 -32 -20 -29 -21 -107 -1 -135 21 -30
75 -46 117 -35 45 11 33 28 -19 24 -32 -3 -46 1 -63 18 -38 38 -23 55 53 58
66 3 67 3 67 31 0 56 -63 94 -116 71z m71 -36 c25 -26 12 -39 -43 -42 -56 -3
-65 7 -35 40 20 22 56 23 78 2z"/>
					<path d="M7680 2778 c-45 -24 -63 -93 -40 -153 13 -35 74 -60 118 -49 42 11
42 30 0 26 -16 -2 -40 -1 -52 2 -24 6 -52 54 -39 67 4 4 37 9 73 11 l65 3 -3
30 c-6 57 -70 90 -122 63z m80 -37 c18 -34 12 -41 -39 -41 -57 0 -66 8 -45 38
20 28 69 30 84 3z"/>
					<path d="M7921 2773 c-18 -12 -26 -13 -29 -5 -2 7 -10 12 -18 12 -11 0 -14
-20 -14 -100 0 -82 3 -100 15 -100 12 0 15 14 15 68 0 59 3 72 23 90 13 12 33
22 46 22 14 0 21 5 19 13 -7 19 -31 19 -57 0z"/>
					<path d="M8040 2777 c-28 -14 -38 -51 -19 -73 6 -8 31 -23 54 -33 50 -22 61
-36 44 -58 -10 -11 -26 -14 -61 -11 -55 6 -69 -15 -18 -25 45 -9 89 3 107 29
22 35 9 57 -53 87 -40 20 -54 32 -52 45 3 13 14 17 53 18 52 1 71 17 33 28
-35 9 -60 7 -88 -7z"/>
					<path d="M8210 2601 c0 -11 4 -22 8 -25 14 -9 35 12 29 28 -9 23 -37 20 -37
-3z"/>
					<path d="M8312 2598 c2 -13 10 -23 18 -23 8 0 16 10 18 23 3 17 -2 22 -18 22
-16 0 -21 -5 -18 -22z"/>
					<path d="M8422 2598 c2 -12 10 -23 18 -23 22 0 19 39 -3 43 -15 3 -18 -1 -15
-20z"/>
					<path d="M2003 2353 c-39 -5 -43 -18 -8 -27 19 -5 25 -13 25 -34 0 -16 -11
-115 -25 -220 -33 -252 -33 -242 9 -242 34 0 35 1 41 48 20 154 51 216 104
210 l26 -3 1 -110 c1 -141 -1 -134 31 -146 21 -8 33 -5 64 15 36 24 58 60 44
74 -4 4 -11 -1 -16 -11 -6 -9 -19 -17 -31 -17 -20 0 -20 3 -13 94 9 126 -5
156 -76 156 -26 0 -44 -9 -72 -34 l-37 -34 6 51 c3 29 10 79 15 112 5 33 9 75
9 93 0 35 -6 36 -97 25z"/>
					<path d="M5835 2351 c-32 -7 -37 -26 -7 -26 25 0 26 -39 2 -249 -16 -138 -18
-191 -10 -206 45 -83 197 -56 267 49 25 36 39 107 29 147 -12 44 -50 74 -97
74 -34 0 -47 -6 -76 -35 -19 -19 -36 -35 -37 -35 -1 0 4 51 11 113 6 61 12
127 13 145 0 30 -2 32 -32 31 -18 -1 -46 -4 -63 -8z m174 -271 c40 -21 33
-130 -11 -193 -29 -40 -64 -52 -96 -31 -22 15 -24 21 -19 69 6 61 37 127 69
149 26 19 33 19 57 6z"/>
					<path d="M3897 2337 c-14 -11 -54 -137 -142 -447 -19 -68 -35 -125 -35 -127 0
-7 59 -2 64 6 11 18 166 555 163 565 -5 14 -34 16 -50 3z"/>
					<path d="M4094 2339 c-11 -18 -164 -549 -164 -569 0 -7 13 -10 31 -8 l32 3 82
285 c46 157 81 287 79 289 -7 7 -55 7 -60 0z"/>
					<path d="M6995 2300 c-15 -16 -17 -26 -9 -48 12 -35 39 -39 59 -9 13 20 14 29
4 51 -15 31 -30 33 -54 6z"/>
					<path d="M2933 2232 c-7 -4 -15 -26 -19 -48 -7 -47 -11 -55 -38 -70 -32 -17
-33 -38 -2 -30 26 6 26 5 21 -31 -14 -113 -25 -206 -31 -267 -5 -55 -10 -70
-25 -73 -11 -3 -19 -12 -19 -20 0 -10 6 -13 16 -9 9 3 45 6 80 6 36 0 64 4 64
10 0 6 -9 10 -19 10 -25 0 -32 22 -25 71 6 36 9 39 41 39 99 0 178 82 190 196
5 51 4 55 -30 89 -32 32 -39 35 -96 35 l-62 0 7 50 c6 48 6 50 -17 50 -13 0
-29 -4 -36 -8z m117 -122 c56 -30 46 -182 -15 -244 -18 -18 -70 -22 -80 -6 -9
15 15 232 28 248 11 15 41 16 67 2z"/>
					<path d="M6612 2223 c-5 -10 -12 -35 -16 -56 -5 -27 -14 -41 -32 -49 -30 -14
-33 -43 -4 -35 27 7 26 3 -16 -329 -4 -32 -10 -43 -27 -48 -40 -10 -8 -18 69
-18 84 1 100 6 60 22 -25 9 -27 14 -24 57 l3 48 53 7 c65 7 115 44 149 108 25
46 31 126 13 161 -17 30 -64 49 -124 49 -46 0 -54 3 -51 18 16 82 16 82 -15
82 -18 0 -33 -7 -38 -17z m133 -128 c51 -50 20 -211 -47 -243 -30 -14 -54 -8
-60 16 -8 33 20 235 34 243 23 15 48 10 73 -16z"/>
					<path d="M2427 2172 c-31 -32 -57 -60 -57 -64 0 -3 8 -8 19 -11 17 -4 18 -9 4
-107 -16 -118 -8 -156 35 -167 34 -8 86 21 108 63 21 38 11 59 -12 26 -20 -29
-52 -29 -59 1 -3 12 1 58 7 102 l13 80 43 3 c55 4 58 29 5 34 l-38 3 3 48 c2
26 0 47 -5 47 -5 0 -34 -26 -66 -58z"/>
					<path d="M2668 2170 c-32 -32 -58 -60 -58 -63 0 -3 10 -7 21 -10 19 -5 21 -11
15 -44 -3 -21 -9 -73 -13 -116 -5 -66 -3 -80 12 -97 36 -40 107 -14 141 52 19
38 9 51 -17 20 -10 -13 -26 -22 -36 -20 -16 3 -18 14 -16 78 2 105 13 130 58
130 24 0 35 5 35 15 0 11 -11 15 -36 15 l-37 0 6 50 c8 65 -3 64 -75 -10z"/>
					<path d="M4442 2150 c-11 -18 -19 -20 -75 -14 -73 7 -108 -6 -151 -57 -38 -45
-56 -94 -56 -153 0 -42 5 -57 26 -80 24 -25 32 -28 71 -23 27 3 54 14 69 28
l25 23 22 -28 c20 -24 28 -27 55 -21 30 6 72 50 72 74 0 16 -17 13 -24 -4 -7
-19 -35 -20 -42 -1 -3 7 4 68 15 135 12 68 21 127 21 132 0 16 -15 10 -28 -11z
m-64 -64 c6 -46 -29 -167 -54 -193 -66 -66 -109 29 -68 150 19 55 47 79 89 75
25 -3 31 -8 33 -32z"/>
					<path d="M6448 2150 c-11 -18 -20 -19 -74 -14 -47 5 -68 3 -94 -11 -85 -44
-139 -170 -107 -248 31 -73 107 -77 177 -9 12 13 15 11 23 -11 17 -51 87 -43
121 14 22 37 14 49 -14 24 -16 -14 -23 -16 -32 -7 -9 9 -7 42 8 143 20 128 17
160 -8 119z m-60 -56 c2 -12 -6 -59 -18 -105 -26 -107 -58 -139 -106 -105 -38
28 -20 159 30 214 27 31 89 28 94 -4z"/>
					<path d="M3583 2125 c-29 -31 -29 -46 0 -73 29 -27 35 -27 70 3 33 29 34 45 4
73 -29 28 -46 27 -74 -3z"/>
					<path d="M3294 2122 c-59 -38 -49 -93 31 -164 41 -36 46 -46 43 -77 -3 -33 -6
-36 -36 -39 -29 -3 -34 1 -47 32 -12 27 -21 36 -39 36 -28 0 -37 -16 -25 -48
17 -46 114 -57 170 -19 41 27 57 74 40 115 -6 15 -34 46 -62 68 -39 31 -49 44
-47 64 5 38 37 38 68 -1 29 -34 60 -35 60 -2 0 34 -18 46 -72 50 -40 3 -60 -1
-84 -15z"/>
					<path d="M4536 2107 c-27 -35 -34 -67 -16 -67 6 0 13 7 16 15 8 20 31 19 38
-3 3 -9 1 -44 -5 -77 -6 -33 -13 -79 -16 -102 l-6 -43 36 0 c36 0 47 12 47 53
0 41 40 181 57 199 18 20 48 24 59 6 14 -21 1 -159 -22 -240 -5 -15 1 -18 35
-18 23 0 41 1 41 3 0 1 7 40 15 86 24 135 62 195 109 169 19 -11 21 -50 5
-133 -15 -80 -6 -123 28 -131 16 -4 36 -1 51 7 31 18 68 66 58 76 -4 5 -13 0
-20 -10 -7 -9 -21 -17 -30 -17 -16 0 -17 7 -9 68 18 151 4 192 -68 192 -28 0
-47 -8 -72 -30 -19 -16 -37 -30 -40 -30 -2 0 -10 14 -17 30 -10 25 -18 30 -47
30 -37 0 -82 -19 -102 -44 -18 -21 -31 -20 -31 2 0 51 -58 56 -94 9z"/>
					<path d="M5174 2121 c-36 -22 -50 -61 -33 -94 7 -12 36 -45 65 -72 49 -46 53
-53 46 -80 -7 -24 -14 -31 -40 -33 -29 -3 -34 1 -47 32 -12 27 -21 36 -39 36
-47 0 -39 -62 10 -80 67 -26 154 3 175 58 16 41 -4 94 -49 128 -56 43 -64 52
-60 76 5 36 38 35 68 -3 29 -35 60 -35 60 0 0 48 -97 68 -156 32z"/>
					<path d="M5432 2124 c-23 -16 -52 -64 -52 -86 1 -7 9 -3 20 11 11 13 26 21 34
18 11 -5 11 -18 0 -89 -19 -118 -18 -145 6 -153 50 -16 115 27 153 100 l16 30
1 -55 c0 -59 12 -80 44 -80 27 0 83 40 96 69 15 33 4 39 -21 12 -36 -38 -45
-14 -30 82 26 164 28 147 -11 147 -34 0 -35 -2 -53 -61 -21 -69 -81 -175 -103
-184 -29 -11 -33 12 -17 106 19 114 12 149 -33 149 -15 0 -38 -7 -50 -16z"/>
					<path d="M6933 2133 c-29 -6 -30 -19 -2 -26 18 -5 21 -11 16 -39 -36 -212 -32
-248 24 -248 34 0 99 55 99 84 0 22 -8 20 -29 -6 -20 -25 -41 -17 -41 15 0 12
9 64 20 116 11 52 20 98 20 103 0 8 -66 9 -107 1z"/>
					<path d="M7462 2123 c-53 -26 -94 -94 -99 -168 -5 -58 -4 -64 25 -96 28 -32
34 -34 94 -34 64 0 109 20 123 54 9 23 -3 26 -25 6 -32 -29 -88 -25 -116 9
-45 52 -25 192 31 218 23 10 29 8 65 -27 25 -24 44 -36 54 -32 24 9 20 53 -6
71 -29 21 -104 20 -146 -1z"/>
					<path d="M7768 2119 c-56 -29 -88 -82 -88 -147 0 -97 46 -152 127 -152 56 0
100 32 128 93 42 89 31 172 -27 207 -41 25 -92 25 -140 -1z m96 -5 c13 -5 16
-22 16 -81 0 -132 -34 -206 -86 -186 -29 10 -34 23 -34 88 1 137 39 204 104
179z"/>
					<path d="M8016 2109 c-14 -17 -26 -39 -26 -50 0 -23 15 -25 24 -4 8 21 34 19
41 -4 3 -11 -1 -57 -10 -102 -22 -116 -21 -119 19 -119 33 0 33 1 45 63 30
157 54 207 99 207 l24 0 -6 -87 c-3 -49 -9 -109 -12 -135 l-6 -48 36 0 c20 0
36 1 36 3 0 4 30 163 36 189 7 32 47 78 67 78 36 0 41 -27 28 -133 -14 -114
-10 -134 27 -144 31 -7 67 11 95 49 24 32 16 49 -11 25 -37 -33 -44 -22 -35
56 12 110 8 149 -17 169 -40 33 -117 14 -152 -36 -8 -12 -7 -12 -31 27 -13 20
-26 27 -48 27 -33 0 -79 -20 -98 -44 -18 -21 -31 -20 -31 2 0 49 -57 56 -94
11z"/>
					<path d="M3570 1915 l-34 -35 29 -30 c16 -16 32 -30 37 -30 12 0 68 55 68 67
0 9 -52 63 -61 63 -3 0 -20 -16 -39 -35z"/>
					<path d="M7171 1916 c-36 -37 -37 -34 17 -80 22 -19 23 -19 52 9 17 16 30 34
30 40 0 11 -51 65 -61 65 -3 0 -20 -15 -38 -34z"/>
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
